/* Keep using Tailwind CSS base, but don't use TW classes. */
/* Tailwind CSS base fixes a lot of browser issues a sets expected default styles. */
@tailwind base;
/* @tailwind components; */
/* @tailwind utilities; */

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Poppins", sans-serif; /* to fix TW */
  /* background-color: transparent !important; */
}

/* Corrección de NMI para Google Chrome (iframe height 0) */
#ccnumber > iframe,
#ccexp > iframe,
#cvv > iframe {
  height: 45px !important;
}

/*  
    Tailwind base replaces these rules from _type.scss 
    h1, .h1 { @include font-size($h1-font-size); }
    h2, .h2 { @include font-size($h2-font-size); }
    h3, .h3 { @include font-size($h3-font-size); }
    h4, .h4 { @include font-size($h4-font-size); }
    h5, .h5 { @include font-size($h5-font-size); }
    h6, .h6 { @include font-size($h6-font-size); }

    $font-size-base:              1rem !default; 
    $h1-font-size:                $font-size-base * 2.5 !default;
    $h2-font-size:                $font-size-base * 2 !default;
    $h3-font-size:                $font-size-base * 1.75 !default;
    $h4-font-size:                $font-size-base * 1.5 !default;
    $h5-font-size:                $font-size-base * 1.25 !default;
    $h6-font-size:                $font-size-base !default;    

    The following lines fix them.
*/

/*
NOTE 2022-06-29: KEEP USING TAILWIND SIZES!
h1,
.h1 {
  font-size: 2.5rem;
}
h2,
.h2 {
  font-size: 2rem;
}
h3,
.h3 {
  font-size: 1.75rem;
}
h4,
.h4 {
  font-size: 1.5rem;
}
h5,
.h5 {
  font-size: 1.25rem;
}
h6,
.h6 {
  font-size: 1rem;
}
*/

/* to fix TW */
a {
  color: #ba08b5;
}

html,
html a,
h1,
h2,
h3,
h4,
h5,
h6,
a,
p,
li,
dl,
th,
dt,
input,
textarea,
span,
div {
  text-shadow: rgba(0, 0, 0, 0.004) 1px 1px 1px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.card form label + .react-select-container {
  margin-bottom: 0px !important;
}

/* Corrección a los títulos de los Cards */
.card .card-header .card-title {
  font-weight: 300;
}

/* CollectJS */

/* Corrección del iframe de CollectJS (Tailwinds) */
#ccnumber iframe:focus-visible,
#ccexp iframe:focus-visible,
#cvv iframe:focus-visible {
  outline: none !important;
}

/* El borde derecho del ccnumber se corta. */
/*
#ccnumber iframe {
  padding-right: 1px;
}
*/

#ccnumber input,
#ccexp input,
#cvv input {
  /* margin-right: 3px; */

  border: 1px solid rgba(29, 37, 59, 0.5);
  color: #222a42;

  /* Común en dark y light mode */
  border-radius: 0.4285rem;
  transition: color 0.3s ease-in-out, border-color 0.3s ease-in-out,
    background-color 0.3s ease-in-out;

  padding: 11px 18px 11px 18px;
  font-size: 0.75rem;
  display: block;
  width: 100%;

  /* height: calc(2.25rem + 2px); */
  height: 38px;

  font-weight: 400;
  line-height: 1.42857;
  background-color: transparent;
  background-clip: padding-box;
  box-shadow: none;
  text-shadow: rgba(0, 0, 0, 0.004) 1px 1px 1px;
}

html[dark="true"] #ccnumber input,
html[dark="true"] #ccexp input,
html[dark="true"] #cvv input {
  border: 1px solid #2b3553;
  color: rgba(255, 255, 255, 0.8);
}

/* Corrección de la posición de los Tim Icons en botones */
.btn:not(.btn-icon) .tim-icons,
.navbar .navbar-nav > a.btn:not(.btn-icon) .tim-icons {
  top: -1px;
  margin-left: 2px;
  margin-right: 2px;
}

/* Corrección del color de fondo de select tag */
html[dark="true"] select option {
  background: #212529;
  /* background: rgba(0, 0, 0, 0.3); */
}

/* Pricing plan selection */
ul.sk-pricing-plans li.nav-item a.nav-link {
  margin-left: 0px !important;
  margin-right: 0px !important;
  margin-bottom: 0px !important;
}

ul.sk-pricing-plans .sk-pricing-table {
  margin-bottom: 15px;
}

/* XSmall */
@media screen and (max-width: 575px) {
  ul.sk-pricing-plans li {
    display: block;
    min-width: 250px;
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 20px;
  }

  ul.sk-pricing-plans li:last-child {
    margin-bottom: 0px;
  }
}

/*  Small */
@media screen and (min-width: 576px) {
  ul.sk-pricing-plans li {
    display: block;
    min-width: 250px;
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 20px;
  }

  ul.sk-pricing-plans li:last-child {
    margin-bottom: 0px;
  }
}

/*  Medium */
@media screen and (min-width: 768px) {
  ul.sk-pricing-plans li {
    display: block;
    min-width: 250px;
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 20px;
  }

  ul.sk-pricing-plans li:last-child {
    margin-bottom: 0px;
  }
}

/*  Large */
@media screen and (min-width: 992px) {
  ul.sk-pricing-plans li {
    min-width: 240px;
    margin-left: 10px;
    margin-right: 10px;
  }
}

/*  XLarge */
@media screen and (min-width: 1200px) {
  ul.sk-pricing-plans li {
    min-width: 240px;
    margin-left: 10px;
    margin-right: 10px;
  }

  /*
  ul.sk-pricing-plans .sk-pricing-table {
    
  }
  */
}

/*
  .bd-docs .navbar .navbar-nav .nav-item:not(:last-child) {
    margin-bottom: 0;
  }
  .bd-docs .navbar .navbar-nav .nav-item:first-child {
    margin-top: 0;
  }
  */

/*
    react-select style customizing
*/

.white-content .react-select__control {
  color: #222a42;
  border-color: rgba(29, 37, 59, 0.5);
  border-radius: 0.4285rem;
}

.react-select__control {
  border: 1px solid #2b3553;
  border-radius: 0.4285rem;
  font-size: 0.75rem;
  transition: color 0.3s ease-in-out, border-color 0.3s ease-in-out,
    background-color 0.3s ease-in-out;
  display: block;
  width: 100%;
  height: calc(2.25rem + 2px);
  font-weight: 400;
  line-height: 1.42857;
  color: rgba(255, 255, 255, 0.8);
  background-color: transparent;
  background-clip: padding-box;
  box-shadow: none;
}

.react-select__control--is-focused {
  border-color: #1d8cf8;
}

.form-group:not(.has-danger):not(.has-success)
  .react-select__control--is-focused,
*
  .input-group-focus:not(.has-danger):not(.has-success)
  .react-select__control--is-focused,
.login-page
  .form-group:not(.has-danger):not(.has-success)
  .react-select__control--is-focused,
.login-page
  .input-group-focus:not(.has-danger):not(.has-success)
  .react-select__control--is-focused,
.card-login.card-white
  .form-group:not(.has-danger):not(.has-success)
  .react-select__control--is-focused,
.card-login.card-white
  .input-group-focus:not(.has-danger):not(.has-success)
  .react-select__control--is-focused {
  border-color: #1d8cf8;
  box-shadow: none;
}

.white-content
  .form-group:not(.has-danger):not(.has-success)
  .react-select__control--is-focused,
.white-content
  .input-group-focus:not(.has-danger):not(.has-success)
  .react-select__control--is-focused {
  border-color: #1d8cf8;
}

.form-group.is-invalid > .invalid-feedback {
  display: block;
}

/*
    reactstrap custom-file-input style customizing
*/

.white-content .custom-file-label,
.white-content .custom-file-label::after {
  color: gray;
}

input.custom-file-input {
  border-radius: 0.4285rem;
  font-size: 0.75rem;
}

.white-content input.custom-file-input {
  border-color: rgba(29, 37, 59, 0.5);
}

.dot-label {
  color: #383838;
}

/*
    Stop HeadlessUI menu being cut off in responsive tables
    From: https://dcblog.dev/stop-bootstrap-drop-menus-being-cut-off-in-responsive-tables
*/
.table-responsive {
  overflow: visible;
}

/*
@media (max-width: 767px) {
  .table-responsive .dropdown-menu {
    position: static !important;
  }
}
@media (min-width: 768px) {
  .table-responsive {
    overflow: visible;
  }
}
*/

/* react-player fix */
.react-player__preview {
  background-size: contain !important;
  background-repeat: no-repeat !important;
}

/* reactstrap dropdown opacity 0 bug fixed: https://github.com/react-bootstrap/react-bootstrap/issues/5065 */
.dropdown-menu.show {
  opacity: 1 !important;
  pointer-events: auto !important;
}

/* fix button displacement in input-groups */
.input-group-append > .btn:hover {
  -webkit-transform: none !important;
  -moz-transform: none !important;
  -o-transform: none !important;
  -ms-transform: none !important;
  transform: none !important;
}

/* fixes invalid border color on focus for input-group */
* .input-group:not(.has-danger):not(.has-success) .form-control:focus,
card-login.card-white
  .input-group:not(.has-danger):not(.has-success)
  .form-control:focus {
  border-color: #1d8cf8;
}

/* react-select errors */
.react-select .react-select__control {
  padding-top: 2px;
  padding-left: 8px;
  padding-right: 8px;
}
.has-error .react-select .react-select__control,
.is-invalid .react-select .react-select__control {
  border-color: #ff8d72;
}
.has-error .invalid-feedback,
.is-invalid .invalid-feedback {
  display: block;
}

/* Headless UI Menu in light mode */
.white-content [id^="headlessui-menu-items-"] {
  background-color: #fff !important;
}
.white-content [id^="headlessui-menu-item-"] {
  color: rgb(154, 154, 154) !important;
}
.white-content [id^="headlessui-menu-item-"][data-active="true"] {
  background-color: rgba(0, 0, 0, 0.03) !important;
}
.white-content [id^="headlessui-menu-item-"] span {
  color: rgb(29, 37, 59) !important;
}

/* Input content is too dark in dark mode */
.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control {
  color: var(--light);
}

/* Publications Preview Box */
.white-content .sk-publication-preview-container {
  border-top-color: var(--lighter);
  border-right-color: var(--lighter);
  border-bottom-color: var(--lighter);
  border-left-color: var(--lighter);
}

.white-content .sk-publication-action-button-wrapper:hover > svg,
.white-content .sk-publication-action-button-wrapper:focus > svg {
  color: var(--dark);
}

/* Modal Dialog Input Fixed */
.modal-dialog .form-control {
  color: #222a42;
  border-color: rgba(29, 37, 59, 0.5);
}

/* Remove highlight on iOS */
body {
  -webkit-top-highlight-color: transparent;
}

/* Bar loader color correction */
.white-content .sk-bar-loader {
  color: var(--primary);
}

/* Three dots on NavBar header */
.white-content .sk-header-navbar-kebab span {
  background: #1d253b;
}

.sk-header-navbar-dropdown:hover,
.sk-header-navbar-dropdown:focus {
  color: var(--white);
}

.white-content .sk-header-navbar-dropdown:hover,
.white-content .sk-header-navbar-dropdown:focus {
  color: rgb(29, 37, 59);
}

/* SideBar Logo Correction */
.sidebar .logo .logo-mini .logo-img img {
  border-radius: 0% !important;
}
.sidebar .logo .logo-mini .logo-img img {
  width: 100% !important;
}

/* Running in standalone mode */
/* 
@media screen and (display-mode: standalone) {
  body {
    background-color: transparent !important;
  }
  .main-panel {
    margin-top: 28px;
  }
}
*/

/* .main-panel {
  margin-top: 28px;
}
body {
  background-color: transparent !important;
}
.sidebar {
  margin-top: 110px;
}
.main-panel nav {
  background: linear-gradient(rgb(30, 30, 47), rgb(30, 30, 36)) !important;
} */

/* margin-top: 82px + 28px; */

/* .main-panel nav {
  margin-top: 28px;
} */
